import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { userToolStore } from '@/store';
let OpenNewTabComponent = class OpenNewTabComponent extends Vue {
    get getUserToolsVisualizar() {
        let retorno = false;
        // @ts-ignore
        const tool = userToolStore.userToolsIndexed[this.propToolName];
        if (tool) {
            // @ts-ignore
            if (tool[0].allow_view) {
                retorno = true;
            }
        }
        return retorno;
    }
    async abrirCadastro() {
        let entidade_tipo = '';
        let toolNameNormalized = this.propToolName
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
        toolNameNormalized = toolNameNormalized.replaceAll(' ', '-');
        const lastChar = this.propToolName.slice(-1);
        if (lastChar == 's') {
            entidade_tipo = `main-${toolNameNormalized}-edit`;
        }
        else {
            entidade_tipo = `main-${toolNameNormalized}s-edit`;
        }
        entidade_tipo = entidade_tipo.toLowerCase();
        const rota = this.$router.resolve({
            name: entidade_tipo,
            params: { id: this.propObjectId },
        });
        window.open(rota.href, '_blank');
    }
};
__decorate([
    Prop({ type: Number })
], OpenNewTabComponent.prototype, "propObjectId", void 0);
__decorate([
    Prop({ type: String })
], OpenNewTabComponent.prototype, "propToolName", void 0);
__decorate([
    Prop({ type: String })
], OpenNewTabComponent.prototype, "propObjectLabel", void 0);
OpenNewTabComponent = __decorate([
    Component
], OpenNewTabComponent);
export default OpenNewTabComponent;
