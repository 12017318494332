var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Análise de contratos")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-row',[(_vm.totals.por_contrato_status)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por status","prop-items":_vm.totals.por_contrato_status}})],1):_vm._e(),(_vm.totals.por_contrato_curso)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por curso","prop-items":_vm.totals.por_contrato_curso}})],1):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',[_c('GenericListItemsCardComponent',{attrs:{"propTableName":"list_contratos"},scopedSlots:_vm._u([{key:"item.aluno_nome",fn:function(ref){
var item = ref.item;
return [_c('OpenNewTabComponent',{attrs:{"propObjectLabel":("" + (item.aluno_nome)),"propObjectId":item.aluno_id,"propToolName":"Aluno"}})]}},{key:"item.idade",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getAge(item.aluno_data_nascimento)))]}},{key:"item.taxa_matricula_valor",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(value)))]}},{key:"item.curso_valor_integral",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(value)))]}},{key:"item.valor_parcela_integral",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(value)))]}},{key:"item.vigencia_inicio",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(value)))]}},{key:"item.vigencia_fim",fn:function(ref){
var item = ref.item;
return [(item.vigencia_fim)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.vigencia_fim))+" ")]):_vm._e()]}},{key:"item.vigencia_fim_previsao",fn:function(ref){
var item = ref.item;
return [(item.vigencia_fim_previsao)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.vigencia_fim_previsao))+" ")]):_vm._e()]}},{key:"item.trancado_previsao_retorno",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(value)))]}},{key:"item.cancelado_data_hora",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value)))]}},{key:"item.trancado_data_hora",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value)))]}},{key:"item.modalidade_id",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.cursoModalidadeOptions()[value - 1].nome))]}},{key:"item.requisitou_transporte",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("boolToStr")(value)))]}},{key:"item.cancelado_notificacao_assinada_pelo_aluno",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("boolToStr")(value)))]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }